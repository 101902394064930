@import './variables.less';
@import './mixins.less';

@media screen and (min-width: 375px) {
  header {
    padding: 1.8rem 0.8rem 1.6rem;

    &.travel-accommodation-bg h1.smaller {
      font-size: 2.2rem;
      letter-spacing: 0.7rem;
    }
  }
}

@media screen and (min-width: 425px) {
  header.travel-accommodation-bg h1.smaller {
    font-size: 2.3rem;
    letter-spacing: 0.9rem;
  }

  #gallery-app {
    form,
    input {
      width: 320px;
    }
  }
}

@media screen and (min-width: 500px) {
  header.travel-accommodation-bg h1.smaller {
    font-size: 2.6rem;
    letter-spacing: 1.2rem;
    padding-left: 1.2rem;
  }
}

@media screen and (min-width: 768px) {
  header.travel-accommodation-bg h1.smaller {
    font-size: 2.6rem;
    letter-spacing: 1.2rem;
    padding-left: 1.2rem;
  }

  .menu {
    flex-direction: row;
    justify-content: space-around;
    padding: 2.4rem 2.4rem 0;

    a {
      margin: 0;
      padding: 0.4rem 0;
      font-size: 1.2rem;
    }

    hr {
      display: none;
    }
  }

  .content {
    padding: 0.5rem 2.4rem;
  }

  #gallery-app {
    .upload-container {
      .drop-container {
        display: flex;
        flex-direction: column;
        width: 400px;
        height: 200px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        border: 2px dotted @primary-select;
      }

      h3.superscript {
        display: block;
        margin-bottom: 1rem;
      }

      .drag-hover {
        background-color: @secondary;
      }
    }

    .images-container {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (min-width: 961px) {
  .menu {
    justify-content: center;

    a {
      padding: 1rem 2.2rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .content {
    padding: 0.5rem 10rem;

    form {
      max-width: 480px;
    }

    iframe {
      width: 480px;
      height: 585px;
    }
  }

  #the-day header.the-day-bg {
    background-image: url('./images/the-day-bg.jpg');
  }

  #gifts header.gifts-bg {
    background-image: url('./images/gifts-large-device.jpg');
  }

  #gallery {
    header.gallery-bg {
      background-image: url('./images/gallery-large-device.jpg');
    }

    .content {
      padding: 0.5rem 2.4rem;
    }

    #gallery-app {
      .upload-container .drop-container {
        width: 550px;
      }

      .images-container {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #gallery-app .images-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1500px) {
  #gallery-app .images-container {
    padding: 0 5rem;
  }
}
