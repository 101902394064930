@import './variables.less';
@import './mixins.less';

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: 325px;
  padding: 1.8rem 0 1.6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.landing-bg {
    background-image: url('./images/landing-bg.jpg');
    opacity: 0.9;
  }

  &.the-day-bg {
    background-image: url('./images/the-day-mobile.jpg');
  }

  &.travel-accommodation-bg {
    background-image: url('./images/travel-accommodation-bg.jpg');

    h1.smaller {
      font-size: 1.8rem;
      letter-spacing: 0.7rem;
      padding-left: 0.5rem;
    }
  }

  &.gifts-bg {
    background-image: url('./images/gifts-bg.jpg');
  }

  &.gallery-bg {
    background-image: url('./images/gallery.jpg');
  }

  h1 {
    padding-left: 0.8rem;
    .transform-animation(fadein);

    &.superscript {
      padding-left: 0;
    }
  }

  .details {
    padding: 1.5rem 0.5rem 0;
    .transform-animation(fadein, 2s);

    h2 {
      font-size: 1.6rem;
      color: @header;
      .legibility-shadow;
    }
  }
}

footer {
  min-height: 40px;

  #scroll {
    height: 40px;
    width: 40px;
    left: calc(50% - 20px);
    bottom: 15px;
    position: fixed;
    padding: 5px 0;
    border-radius: 50%;
    background-color: @primary;
    opacity: 0;
    color: @background;
    transition: 0.5s ease;
    transition-property: opacity;
    pointer-events: none;
    z-index: 1;

    &.visible {
      opacity: 1;
      pointer-events: initial;
    }

    &:hover,
    &:focus {
      background-color: @primary-select;
    }

    svg {
      width: 30px;
      padding-top: 4px;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  padding: 2.4rem 0 0;
  font-weight: 600;

  a {
    width: auto;
    margin: 0 auto;
    padding: 0.4rem 2rem;
    font-size: 1.2rem;
    line-height: 2;

    &.active {
      color: @active-link;
    }

    &:focus {
      .outline-style();
    }
  }
}

hr {
  border: 1px solid @secondary;
  width: 3.2rem;
}

.superscript {
  font-size: 1.2rem;
  letter-spacing: 0.3rem;
  margin: 0;
}

h3 .superscript {
  padding-left: 0.2rem;
  letter-spacing: 0.2rem;
}

.content {
  padding: 0.5rem 2rem;

  #javascript-disabled p {
    margin-top: 2rem;
    color: @warning;
    font-size: 1.1rem;

    span {
      width: 100%;
      display: block;
      font-size: 2rem;
    }
  }

  select {
    max-width: 15rem;
    margin-bottom: 1rem;
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    border: none;
    border-radius: 1px;
    background-color: lighten(@secondary, 3%);
    background-image: url('./images/chevron-down.svg');
    background-position: right 0.75rem top 50%, 0 0;
    background-repeat: no-repeat;
    color: @body;
    font-family: @body-font;
    font-size: 1rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &:focus {
      .outline-style();
    }
  }

  form {
    margin: 1rem auto;
    max-width: 450px;

    .bot-field {
      visibility: hidden;
      height: 1px;
    }

    .guest-fields {
      display: none;

      &.active {
        display: block;
        margin: 0.4rem 0;
      }
    }

    hr {
      margin-bottom: 2.2rem;
    }

    label,
    input,
    textarea {
      &:focus {
        .outline-style();

        &::placeholder {
          color: transparent;
        }
      }
    }

    label {
      .header-text();
      margin: 0.5rem 0;
      font-size: 1.1rem;
      letter-spacing: 0.2rem;

      &.checkbox-label {
        display: block;
        font-size: 1.1rem;
      }
    }

    input,
    textarea {
      width: 100%;
      padding: 0.8rem;
      margin: 0.5rem auto;
      border: none;
      text-align: center;
      background-color: @input;
      color: lighten(@body, 2%);
      font-size: 1rem;
      font-family: @body-font;
    }

    input[type='text'] {
      margin-bottom: 0.6rem;
    }

    input[type='checkbox'] {
      width: 1rem;
      margin-right: 0.4rem;
    }

    .dietary-details {
      label {
        display: inline-block;
      }

      .dietary-text-field {
        opacity: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        transition: 0.5s;
        transition-property: opacity, padding;
      }

      input[type='checkbox']:checked ~ .dietary-text-field {
        opacity: 1;
        height: auto;
        padding: 0.8rem;
        margin-bottom: 1.8rem;
        overflow: visible;
      }
    }
  }

  .sub-section {
    padding: 0.2rem 0 0.65rem;

    h4 {
      margin: 1rem 0 0.5rem;
    }

    .item {
      padding: 0.6rem 0;
    }
  }

  iframe,
  img {
    max-width: 100%;
    margin-top: 1rem;
  }

  .venue-details,
  .hotels {
    .sub-section {
      padding-bottom: 1rem;
    }

    h4 {
      margin: 0.4rem 0 0.2rem;
    }

    a.phone-number {
      line-height: 2;
    }

    span {
      display: block;
      padding-bottom: 0.3rem;
    }

    .northern-monk-logo {
      max-width: 5em;
    }

    iframe {
      min-height: 450px;
      width: 600px;
      margin-bottom: 2rem;
    }
  }

  .hotels {
    margin-top: 2.9rem;
  }
}

#rsvp-sent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    padding: 4rem 1rem 0;

    h2 {
      font-size: 2.4rem;
    }

    button {
      padding: 0.8rem 0.8rem 0.8rem 1.2rem;
    }
  }
}

#gifts {
  .item {
    padding: 1.3rem 0;
  }
}

#gallery-app {
  .upload-container {
    button {
      width: 200px;
    }

    #gallery-password p {
      margin: 0 0 2rem 0;
    }

    .drop-container,
    h3.superscript {
      display: none;
    }
  }

  .images-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.3rem;

    img {
      height: auto;
      display: flex;
      align-self: center;
      margin: 0;
    }
  }
}

@import './responsive.less';
